<template>
  <div class="orderWrap">
		<div class="orderContent" id="orderContent" @scroll="handleScroll($event)">
			<div class="orderNodata" v-if="orderList.length==0">
				<img src="https://cdn.qiumo.net.cn/allsmarketmaterial/taiyue_wxgzh/nodata.png" mode="widthFix"></img>
				<div>您还没有相关的订单哦</div>
			</div>
			<div  v-else  class="scrolldiv">
				<div class="orderInfo" v-for="(item,index) in orderList" :key="index">
					<div class="orderItem">
						<div class="orderDesc">付费号码</div>
						<div class="orderResult">{{ item.phone_number }}</div>
					</div>
					<div class="orderItem orderItemSep">
						<div class="orderDesc">产品名称</div>
						<div class="orderResult">{{ item.item_name }}</div>
					</div>
					<div class="orderItem">
						<div class="orderDesc">付费金额</div>
						<div class="orderResult">{{ item.item_price }}元</div>
					</div>
					<div class="orderItem">
						<div class="orderDesc">付费方式</div>
						<div class="orderResult">支付宝</div>
					</div>
					<div class="orderItem">
						<div class="orderDesc">交易时间</div>
						<div class="orderResult">{{ item.create_time }}</div>
					</div>
					<div class="orderItem">
						<div class="orderDesc">交易单号</div>
						<div class="orderResult orderpayNum">{{ item.pay_id }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { record } from "@/api/taiyue/receive.js";
import { mapGetters } from "vuex";

export default {
  components: {
  },
  data() {
    return {
      alert: {
        visibility: false,
        title: "",
        message: "",
      },
      orderList:[],
      page: 1,
      detailIndex:-1,
      cardItem:{}
    };
  },
  computed: {
    ...mapGetters(["taiyue_token", "taiyue_user_info","taiyue_wechat_open_id"]),
  },
  created() {
	this.recordList();
  },
  methods: {
    async recordList() {
      this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
        await record(this.taiyue_token,{ 'page': this.page, 'data_type': 'pay' })
        .then(res =>{
          if (res.code == 0) {
			this.pageCount = res.pages;
			if(this.page == 1){
				this.orderList = res.record;
			}else{
				this.orderList = this.orderList.concat(res.record)
			}
              this.$toast.clear();
          } else {
              this.$toast.clear();
          }
        }).catch(err =>{
          this.$toast.clear();
		  this.$router.replace({
            path: "/fz_taiyue/receive/login",
            query: {
              wechat_open_id: this.taiyue_wechat_open_id,
            },
          });
        })
    },
	// 展开使用说明
	toDetails(index){
		if(this.detailIndex == index){
			this.detailIndex = -1;
		}else{
			this.detailIndex = index;
		}
	},
    handleSubmit(){
      this.visibility = false;
    },
	// 获取页面滚动距离
	handleScroll(e) {
		let height = document.getElementById("orderContent").offsetHeight;
		let scrollTop = e.srcElement.scrollTop || e.target.scrollTop;
		let scrollHeight = e.srcElement.scrollHeight || e.target.scrollHeight;
		if (scrollTop + height >= scrollHeight-20) {
			if (this.page == this.pageCount) {
				return
			}else{
				this.page++;
				this.recordList();
			}
		}
	},
  },
};
</script>

<style lang="scss" scoped>
.orderWrap{
		width: 100%;
		height: 100%;
		background-color: #f5f5f5;
		position: relative;
		.orderContent{
			width: 100%;
			height: 100%;
			padding: 0 30px 30px;
			box-sizing: border-box;
			overflow-y: scroll;
			.orderNodata{
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				img{
					width: 354px;
					margin-top: 170px;
				}
				div{
					color: #999999;
					font-size: 28px;
					margin-top: 16px;
				}
			}
			.scrolldiv{
				width: 100%;
				height: 100%;
				.orderInfo{
					background-color: #fff;
					padding: 30px;
					box-sizing: border-box;
					border-radius: 18px;
					margin-top: 20px;
					.orderItem{
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 10px 0;
						.orderDesc{
							font-size: 26px;
							color: #000;
						}
						.orderResult{
							font-size: 26px;
							color: #888888;
						}
						.orderpayNum{
							font-size: 23px;
						}
					}
					.orderItemSep{
						padding-bottom: 18px;
					}
				}
			}
		}
	}
	
	
	
	
	
</style>
